import React from 'react';

import CustomIcon from '../../components/CustomIcon';
import { useParams } from 'react-router-dom';
import { useTypedSelector } from '@fiji/common/src/app/store';
import { useDebounce, useRBAC } from '../../hooks';
import {
    Container,
    IconButton,
    List,
    Stack,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Skeleton,
    Button,
} from '@mui/material';
import { ChannelValue, InfoListItem } from '@brightlayer-ui/react-components';
import EditNotifications from '@mui/icons-material/EditNotifications';
import UnfoldMore from '@mui/icons-material/UnfoldMore';
import { useGetChannelDetailsWithCategoryQuery } from '@fiji/common/src/features/deviceManagement/deviceApi';
import { useTheme } from '@mui/material/styles';
import { selectCurrentPermission } from '@fiji/common/src/features/profile/profileSlice';
import { NotificationLog } from '@brightlayer-ui/icons-mui';
import { ListSkeleton } from 'pages/WidgetManagement/common/Skeletons';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { ThresholdModal } from './ThresholdModal';
import { useHandleDeviceDetails } from './hooks';
import { CustomEmptyState } from 'components/CustomEmptyState';
import { CustomTransComponent } from 'components';
import { useTransformData } from 'hooks/useTransformData';
import { InputWithCustomPlaceholder } from 'components/InputWithCustomPlaceholder';

export const DeviceDetails = (): JSX.Element => {
    const theme: any = useTheme();
    const [searchKey, setSearchKey] = React.useState<any>(undefined);
    const { deviceId } = useParams();
    const thresholdModalRef = React.useRef<any>(null);

    const selectedNode = useTypedSelector((state) => state['common']['selectedNode']);
    const [, debouncedValue] = useDebounce(undefined, 300, searchKey);
    const { pointConversion } = useTransformData();

    const {
        data: deviceDetails,
        isLoading,
        isFetching: deviceDetailsFetching,
    } = useGetChannelDetailsWithCategoryQuery(
        {
            deviceId: deviceId,
            params: {
                channelType: 'DETAIL',
                ...(debouncedValue && { searchKey: 'displayName', searchValue: debouncedValue }),
            },
        },
        {
            refetchOnMountOrArgChange: true,
        }
    );

    const { thresholdUpdateLoader, handleAllExpandCollapse, expandedCategories, setExpandedCategories } =
        useHandleDeviceDetails(deviceDetails);

    const permissions = useTypedSelector(selectCurrentPermission);
    const { hasPermission } = useRBAC(permissions);
    const canUpdateThreshold = hasPermission('edit-devices');

    return (
        <>
            <Container className="padding-y-36">
                <Stack mb={2} direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                    <Stack direction={'row'} alignItems={'center'} spacing={3}>
                        <InputWithCustomPlaceholder
                            id="searchKey"
                            value={searchKey ?? ''}
                            placeholder={'COMMON:SEARCH_PLACEHOLDER'}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                setSearchKey(e.target.value);
                            }}
                        />
                    </Stack>
                    <Stack direction={'row'} spacing={2} alignItems={'center'}>
                        <Button
                            id="toggleExpand"
                            sx={{
                                border: `1px solid ${theme?.palette?.primary?.main}`,
                                '&:hover': {
                                    backgroundColor: theme?.palette?.primary?.[50],
                                },
                            }}
                            variant="outlined"
                            onClick={handleAllExpandCollapse}
                            startIcon={<UnfoldMore />}
                        >
                            {Object?.values(expandedCategories)?.every((item: any) => item === true)
                                ? 'Collapse All'
                                : '  Expand All'}
                        </Button>
                    </Stack>
                </Stack>

                <Stack>
                    {isLoading || deviceDetailsFetching || thresholdUpdateLoader ? (
                        <Accordion defaultExpanded>
                            <AccordionSummary
                                expandIcon={<ExpandMore color="primary" />}
                                aria-controls="panel1bh-content"
                                id="expand"
                                className="border-bottom-1"
                            >
                                <Skeleton animation="wave">
                                    <Typography
                                        variant="body2"
                                        fontWeight={'600'}
                                        fontSize={'14px'}
                                        sx={{ color: theme.palette?.primary?.main }}
                                    >
                                        <CustomTransComponent translationKey={'COMMON:PRIORITY'} />
                                    </Typography>
                                </Skeleton>
                            </AccordionSummary>
                            <AccordionDetails className="padding0">
                                <ListSkeleton total={10} customSize={25} customSx={{ height: '4.5rem' }} />
                            </AccordionDetails>
                        </Accordion>
                    ) : Object.keys(deviceDetails?.data ?? {})?.length ? (
                        Object.keys(deviceDetails?.data)?.map((item: any) => (
                            <Accordion
                                key={item}
                                expanded={expandedCategories[item] ?? true}
                                onChange={(): void => {
                                    setExpandedCategories((prev: any) => ({ ...prev, [item]: !prev[item] }));
                                }}
                                defaultExpanded
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMore color="primary" />}
                                    aria-controls="panel1bh-content"
                                    id="expand"
                                    className="border-bottom-1"
                                >
                                    <Typography
                                        variant="body2"
                                        fontWeight={'600'}
                                        fontSize={'14px'}
                                        sx={{ color: theme.palette?.primary?.main }}
                                    >
                                        {item ?? '--'}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails className="padding-0">
                                    <List disablePadding>
                                        {deviceDetails?.data[item]?.map((channel: any) => (
                                            <InfoListItem
                                                key={channel?.channelId}
                                                icon={
                                                    <CustomIcon
                                                        family={channel?.icon?.web?.family ?? ''}
                                                        iconName={channel?.icon?.web?.name ?? ''}
                                                    />
                                                }
                                                divider="full"
                                                title={channel?.displayName}
                                                rightComponent={
                                                    <Stack flexDirection={'row'} justifyContent={'space-between'}>
                                                        <ChannelValue
                                                            unitSpace="show"
                                                            value={
                                                                channel?.value !== 'true' && channel?.value !== 'false'
                                                                    ? pointConversion(channel?.value)
                                                                    : channel?.value
                                                            }
                                                            units={channel?.unit}
                                                        />
                                                        {channel?.type?.includes?.('Trend') && (
                                                            <IconButton
                                                                id="threshold"
                                                                disabled={selectedNode?.connectionStatus === 'Offline'}
                                                                onClick={(): void =>
                                                                    thresholdModalRef?.current?.onClick(channel)
                                                                }
                                                            >
                                                                {canUpdateThreshold ? (
                                                                    <EditNotifications />
                                                                ) : (
                                                                    <NotificationLog />
                                                                )}
                                                            </IconButton>
                                                        )}
                                                    </Stack>
                                                }
                                            />
                                        ))}
                                    </List>
                                </AccordionDetails>
                            </Accordion>
                        ))
                    ) : (
                        <CustomEmptyState icon title={<CustomTransComponent translationKey={'COMMON:NO_DETAILS'} />} />
                    )}
                </Stack>
            </Container>

            <ThresholdModal ref={thresholdModalRef} />
        </>
    );
};
