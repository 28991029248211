import React from 'react';
import { FormControl, MenuItem, Select, Switch, TextField } from '@mui/material';
import { useTypedSelector } from '@fiji/common';
import { selectCurrentPermission } from '@fiji/common/src/features/profile/profileSlice';
import { useRBAC } from 'hooks';

export const SettingsRightComponent = ({ item, payload, setPayload, type }: any): JSX.Element => {
    const selectedNode = useTypedSelector((state) => state['common']['selectedNode']);

    const permissions = useTypedSelector(selectCurrentPermission);
    const { hasPermission } = useRBAC(permissions);
    const canUpdateDevice = hasPermission('edit-devices');

    switch (type) {
        case 'toggle':
            return (
                <Switch
                    name={item?.name}
                    disabled={selectedNode?.connectionStatus === 'Offline' || !canUpdateDevice}
                    checked={payload[item?.name] ?? false}
                    onChange={(e): void => {
                        setPayload((prev: any) => ({ ...prev, [e.target.name]: e.target.checked }));
                    }}
                />
            );

        case 'select':
            return (
                <FormControl variant="filled" className="min-width-320">
                    <Select
                        className="custom-select"
                        name={item?.channelId}
                        labelId="demo-simlple-select-filled-label"
                        disabled={selectedNode?.connectionStatus === 'Offline' || !canUpdateDevice}
                        id="demo-simple-select-filled"
                        onChange={(e): void =>
                            setPayload((prev: any) => ({ ...prev, [e.target.name]: e.target.value }))
                        }
                        value={payload[item.channelId] ?? ''}
                    >
                        {item?.values?.map((option: { value: string; name: string }) => (
                            <MenuItem key={option?.value} value={option?.value}>
                                {option?.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            );

        default:
            return (
                <TextField
                    onChange={(e): void => {
                        if (item?.min <= parseInt(e.target.value) && parseInt(e.target.value) <= item.max) {
                            setPayload((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
                        } else {
                            setPayload((prev: any) => ({ ...prev, [e.target.name]: payload[item.channelId] }));
                        }
                    }}
                    disabled={selectedNode?.connectionStatus === 'Offline' || !canUpdateDevice}
                    value={payload[item.channelId] ?? ''}
                    name={item?.channelId}
                    type={item?.dataType === 'Number' ? 'number' : 'text'}
                    id="filled-basic"
                    label={item?.value}
                    variant="filled"
                    className="min-width-320"
                />
            );
    }
};
